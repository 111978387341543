import React, { useMemo, useContext } from "react"
import { BrowserClient, Hub } from "@sentry/browser"

export interface SentryEvent {
  description: string
  info: ErrorInfo
  fingerprints?: Array<string>
  tags?: Record<string, string>
}

interface ErrorInfo {
  cause: Error
  // Let callers attach any helpful debugging info here,
  // it will get picked up as sentry "additional data"
  [key: string]: unknown
}

export type SentryLoggerSimple = (desc: string, cause: Error) => void
export type SentryLoggerDetailed = (options: SentryEvent) => void

/**
 * E.g. user went into a tunnel during a request. Not our problem.
 */
const isLostConnectionError = (error: Error): boolean => {
  const chromeMessage = "Failed to fetch"
  const firefoxMessage = "NetworkError when attempting to fetch resource"
  const connectionLostMessage = "The network connection was lost"
  const requestFailedMessage = "Network request failed"
  const cancelledMessage = "cancelled"
  const connectionOfflineMessage =
    "The internet connection appears to be offline"
  // https://github.com/github/fetch/issues/310#issuecomment-310050224
  return (
    error.name === "TypeError" &&
    (error.message.startsWith(chromeMessage) ||
      error.message.startsWith(firefoxMessage) ||
      error.message.startsWith(connectionLostMessage) ||
      error.message.startsWith(requestFailedMessage) ||
      error.message.startsWith(cancelledMessage) ||
      error.message.startsWith(connectionOfflineMessage))
  )
}

const isAbortedError = (error: Error): boolean => {
  return (
    error.name === "AbortError" &&
    error.message.startsWith("The operation was aborted.")
  )
}

/**
 * Sometimes Sentry is blocked by AdGuard, we don't want to be alerted.
 */
const isAdGuardError = (error: unknown): boolean => {
  if (typeof error === "object" && error !== null) {
    const errorNarrowed = error as { body?: unknown }
    return (
      errorNarrowed.body !== undefined &&
      errorNarrowed.body === "string" &&
      errorNarrowed.body.substring(0, 100).match(/AdGuard/) !== null
    )
  }
  return false
}

const shouldCapture = (error: Error): boolean => {
  return !(
    isLostConnectionError(error) ||
    isAdGuardError(error) ||
    isAbortedError(error)
  ) // ... extend as needed
}

// These global variables are provided by default as part of the base Webpack
// config, via the BuildVarsPlugin. They're not guaranteed to be defined (an app
// might modify its Webpack config, or not use Webpack at all) but if they are
// then we can automatically include them in our Sentry logs
declare const __BUILD_COMMIT__: string | undefined
declare const __BUILD_NUMBER__: string | undefined
declare const __BUILD_TIMESTAMP__: string | undefined

const extraLogInfo = {
  ...(typeof __BUILD_TIMESTAMP__ !== "undefined"
    ? { "build-timestamp": __BUILD_TIMESTAMP__ }
    : {}),
  ...(typeof __BUILD_NUMBER__ !== "undefined"
    ? { "build-number": __BUILD_NUMBER__ }
    : {}),
}

const logException =
  (hub: Hub, isProd: boolean): SentryLoggerDetailed =>
  ({ description, info, fingerprints = [], tags = {} }): void => {
    if (!isProd) {
      console.error(description, { info, extraLogInfo, fingerprints, tags })
      return
    }

    /* Filter out uninteresting errors */
    if (shouldCapture(info.cause)) {
      hub.withScope(scope => {
        if (typeof window !== "undefined") {
          scope.setExtra("user-agent", window.navigator.userAgent)
        }
        scope.setExtras(extraLogInfo)
        scope.setExtras(info)
        scope.setTags(tags)
        if (fingerprints.length > 0) {
          scope.setFingerprint(fingerprints)
        }
        hub.captureException(new Error(description))
      })
    }
  }

export interface SentryContextType {
  logToSentry: SentryLoggerSimple
  logToSentryDetailed: SentryLoggerDetailed
}

export const initSentry = ({
  dsn,
  isProd,
}: {
  dsn: string
  isProd: boolean
}): SentryContextType => {
  const client = new BrowserClient({
    dsn,

    // To find the relevant source map for an error, Sentry matches the release
    // string on an error with the release string associated with uploaded
    // source maps.
    //
    // As with the extraLogInfo variables defined above, __BUILD_COMMIT__ is
    // provided by default as part of the base Webpack config, via the
    // BuildVarsPlugin. It's not guaranteed to be defined (an app might modify
    // its Webpack config, or not use Webpack at all) but if it is then we can
    // automatically include it in our Sentry logs
    ...(typeof __BUILD_COMMIT__ !== "undefined"
      ? { release: __BUILD_COMMIT__ }
      : {}),
  })
  const hub = new Hub(client)
  const log = logException(hub, isProd)
  return {
    logToSentry: (description, cause) => log({ description, info: { cause } }),
    logToSentryDetailed: log,
  }
}

export const SentryContext = React.createContext<SentryContextType | null>(null)

export const useSentry = (): SentryContextType => {
  const ctx = useContext(SentryContext)
  return useMemo(
    () => ({
      logToSentry: ctx === null ? () => {} : ctx.logToSentry,
      logToSentryDetailed: ctx === null ? () => {} : ctx.logToSentryDetailed,
    }),
    [ctx]
  )
}
