module.exports = [{
      plugin: require('../../.yarn/__virtual__/@wardpeet-gatsby-plugin-static-site-virtual-85ea09cd30/0/cache/@wardpeet-gatsby-plugin-static-site-npm-0.3.0-c4df309cd8-a1abfe6f71.zip/node_modules/@wardpeet/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-3e1ad63ea5/0/cache/gatsby-plugin-manifest-npm-3.15.0-6c95d236f5-53c0c46bb4.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Habito","short_name":"Habito","start_url":"/","background_color":"#ffffff","theme_color":"#151515","display":"minimal-ui","icon":"src/assets/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aa37929e7175a4fd3e257ad628049862"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
