// This is a polyfill for the performance.timeOrigin() browser API
// We copied it from Sentry: https://github.com/getsentry/sentry-javascript/blob/0812ccc8e457004369d2c05ecfce56cfe6f00a6d/packages/utils/src/misc.ts#L355-L401

const INITIAL_TIME = Date.now()

const performanceFallback = {
  timeOrigin: INITIAL_TIME,
}

if (window.performance !== undefined) {
  // While performance.timing.navigationStart is deprecated in favor of
  // performance.timeOrigin, performance.timeOrigin is not as widely supported.
  // Namely, performance.timeOrigin is undefined in Safari as of writing.
  if (performance.timeOrigin === undefined) {
    // As of writing, performance.timing is not available in Web Workers in
    // mainstream browsers, so it is not always a valid fallback. In the absence
    // of a initial time provided by the browser, fallback to INITIAL_TIME.
    performance.timeOrigin =
      (performance.timing && performance.timing.navigationStart) || INITIAL_TIME
  }
} else {
  window.performance = performanceFallback
}
